import React, { useEffect, useState } from "react";
import filter from 'lodash/filter';
import find from 'lodash/find';
import { Auth } from "aws-amplify";
import { Form, FormSpy, Field as FinalField } from 'react-final-form';
import get from "lodash/fp/get";

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';

import InputField from "../shared/input-field";
import ToggleField from '../shared/toggle-field';
import CompanySelectField from '../company-select-field';
import SelectField from '../shared/select-field';
import PhoneField from '../shared/phone-field';

import { useFindContacts } from "../../utils/crm/use-contact";
import { useCompany } from "../../utils/crm/use-company";
import phoneFormatting from "../../utils/formatting";

import { useGroups } from "../../utils/crm/use-group";
import { useEmployees } from "../../utils/crm/use-employee";
import { EMAIL_PATTERN, GROUPS_DATA, VIP_DATA } from "../../utils/constants";
import { default as STATES } from '../../utils/states-us';
import { Typography } from "@material-ui/core";

const Field = ({ item, xs, sm, md, ...props }) => (
    <Grid item xs={xs} sm={sm} md={md}>
        <FinalField {...props} />
    </Grid>
);

const GridGroup = ({title, description, children}) => (
    <Grid sm={12} container spacing={1} style={{marginBottom: 10, marginTop: 10}}>
        <Grid item sm={12} md={3}>
            <Typography variant="subtitle1" style={{fontWeight: 600}}>{title}</Typography>
            <Typography variant="caption" >{description}</Typography>
        </Grid> 
        <Grid item sm={12} md={9} container spacing={1}>
            {children}
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
    </Grid>
);

const CheckboxField = ({ label, input, ...rest }) => (
  <FormControlLabel
      style={{padding: 0, paddingTop: 30}}
      control={
        <Checkbox
          {...input} 
          {...rest}
          color="primary"
          checked={input.checked}
        />
      }
      label={label}
  />
);

const Condition = ({ when, is, initialValue, children }) => (
  <FinalField name={when} subscription={{ value: true }}>
    {({ input: { value, ...rest } }) => {
      return((get('id',value) !== get('id',initialValue)) && (get('id',value)) ? children : null)
    }}
  </FinalField>
)

const CustomerContactForm = ({
    onSubmit,
    id,
    initialValues,
    newContact,
    labelSubmit,
    disableInnerSubmit
}) => {

    const required = value => (value ? undefined : 'Required');
    const mustBeEmail = value => (EMAIL_PATTERN.test(value) ? undefined : 'Email not valid');
    const emailExist = async value => {
        // console.log(value)
        try {
            let contacts = await findContacts({ filter: { primary_email: { eq: value.toLowerCase() }}});
            contacts = contacts.filter(contact => contact.id !== initialValues.id);
            const warning = contacts.length > 0
                ? contacts.reduce((acc, cur, index) => {
                    if (index === contacts.length - 1){
                        return acc + cur.first_name + ' ' + cur.last_name + ' ';
                    } else {
                        return acc + cur.first_name + ' ' + cur.last_name + ', ';
                    }
                }, 'Email address in use for the following contact: ')
                : undefined;
            return warning;
        } catch(e){
            console.log(e)
        }
    };
    const composeValidators = (...validators) => value =>
        validators.reduce((error, validator) => error || validator(value), undefined);

    const [helperTextGroup, setHelperTextGroup] = useState({ mailings: null, region: null, industry: null });
    const companyId = get('companyId', initialValues);
    const [{ company } ] = useCompany(companyId);
    const [{ groups }] = useGroups();
    const [{ employees }] = useEmployees();
    const findContacts = useFindContacts();
    const userEmail = Auth.user.attributes.email;
    if(initialValues && !initialValues.length && !newContact){
      const initialGroups = get('groups', initialValues);
      initialValues.mailingGroups = initialGroups ? find(initialGroups, {'type': 'mailings'}) : {};
      initialValues.regionGroups = initialGroups ? find(initialGroups, {'type': 'region'}) : {};
      initialValues.industryGroups = initialGroups ? find(initialGroups, {'type': 'industry'}) : {};
      initialValues.state = STATES.find(el => el.value === initialValues.state);
      initialValues.vip_type = VIP_DATA.find(el => el.name === initialValues.vip_type)
      initialValues.sendNotification = false;
    }
    
    if(newContact) {
      initialValues = { ...initialValues};

      if(initialValues.companyId) {
        initialValues.company = company;
        delete initialValues.companyId;
      }

      initialValues.mailingGroups = find(groups, {'type': 'mailings'});
      initialValues.assignedTo = find(employees, ['email', userEmail]);
    }

    const mailingGroupOptions = filter(groups, ['type', 'mailings']);
    const regionGroupOptions = filter(groups, ['type', 'region']);
    const industryGroupOptions = filter(groups, ['type', 'industry']);
    const [enableNotification, setEnableNotification] = useState(false);

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} >
            {({ handleSubmit }) => (
                <Box onSubmit={handleSubmit} component="form" padding={3} id={id}>
                    <FormSpy
                        subscription={{ values: true }}
                        onChange={({ values }) => {
                            if(get('assignedTo.id',values) !== get('assignedTo.id',initialValues)) {
                                setEnableNotification(true);

                            }else {
                                setEnableNotification(false);
                            }
                            if(values.assignedTo === null){
                                setEnableNotification(false);
                                values.sendNotification = false;
                            }
                            const mailchimpMailings = get('mailchimp_list_id', values.mailingGroups);
                            const mailchimpRegion = get('mailchimp_list_id', values.regionGroups);
                            const mailchimpIndustry = get('mailchimp_list_id', values.industryGroups);
                            const textGroup = 'Group synced with a Mailchimp audience'
                            setHelperTextGroup({ 
                                mailings: mailchimpMailings ? textGroup : null,
                                region: mailchimpRegion ? textGroup : null,
                                industry: mailchimpIndustry ? textGroup : null,
                            });
                        }}
                    />

                    <Grid container spacing={1}>
                        <GridGroup title="Contact Details" description="Email addresses must be unique.">
                            <Field
                                sm={12} md={6}
                                // autoFocus
                                component={InputField}
                                name="first_name"
                                fullWidth
                                label="First Name"
                                validate={required}
                            />
                            <Field
                                sm={12} md={6}
                                component={InputField}
                                name="last_name"
                                fullWidth
                                label="Last Name"
                                // validate={required}
                                parse={value => value == "" ? null : value}
                            />
                            <Field
                                sm={12} md={6}
                                component={InputField}
                                name="primary_email"
                                fullWidth
                                label="Email Address"
                                validate={composeValidators(required, mustBeEmail, emailExist)}
                            />
                            <Field
                                sm={12} md={4}
                                component={PhoneField}
                                name="primary_phone"
                                fullWidth
                                label="Phone #"
                                parse={(value) => {return phoneFormatting(value)}}
                            />
                        </GridGroup>
                        {/* <Field
                            sm={12} md={1}
                            component={ToggleField}
                            name="vip"
                            type="checkbox"
                            fullWidth
                            label="VIP"
                        /> */}
                        <GridGroup title="Status">
                          <Field
                            sm={12} md={4}
                            name="vip_type"
                            label="VIP Level"
                            component={SelectField}
                            options={VIP_DATA}
                            getOptionLabel={get("name")}
                            getOptionValue={get("id")}
                          />
                        </GridGroup>
                        <GridGroup title="Company Details">
                            <Field
                                sm={12} md={6}
                                name="company"
                                label="Company"
                                component={CompanySelectField}
                                getOptionLabel={get("name")}
                                getOptionValue={get("id")}
                                isCreateable
                                marginTop="8px"
                                fullWidth
                            />
                            <Field
                                sm={12} md={6}
                                component={InputField}
                                name="title"
                                fullWidth
                                label="Title"
                                parse={value => value == "" ? "" : value}
                            />
                        </GridGroup>
                        <GridGroup title="Groups">
                            <Field
                                sm={12} md={4}
                                name="mailingGroups"
                                label="Mailings"
                                component={SelectField}
                                options={mailingGroupOptions}
                                getOptionLabel={get("name")}
                                getOptionValue={get("id")}
                                fullWidth
                                helperText={helperTextGroup.mailings}
                            />
                            <Field
                                sm={12} md={4}
                                name="regionGroups"
                                label="Region"
                                component={SelectField}
                                options={regionGroupOptions}
                                getOptionLabel={get("name")}
                                getOptionValue={get("id")}
                                validate={required}
                                fullWidth
                                helperText={helperTextGroup.region}
                            />
                            <Field
                                sm={12} md={4}
                                name="industryGroups"
                                label="Industry"
                                component={SelectField}
                                options={industryGroupOptions}
                                getOptionLabel={get("name")}
                                getOptionValue={get("id")}
                                validate={required}
                                fullWidth
                                helperText={helperTextGroup.industry}
                            />
                        </GridGroup>
                        <GridGroup title="Assignment">
                            <Field
                                sm={enableNotification?8:12} md={6}
                                name="assignedTo"
                                label="Assigned to"
                                component={SelectField}
                                options={employees}
                                getOptionLabel={get("name")}
                                getOptionValue={get("id")}
                                validate={required}
                                fullWidth
                            />
                            <Condition when="assignedTo" is={!null} initialValue={initialValues.assignedTo}>
                            <Field
                                sm={4}
                                md={4}
                                name="sendNotification"
                                type="checkbox"
                                component={CheckboxField}
                                label="Send notification"
                            />
                            </Condition>
                        </GridGroup>
                        <GridGroup title="Contact Address">
                            <Field
                                sm={12} md={6}
                                component={InputField}
                                name="street_address"
                                fullWidth
                                label="Street Address"
                                parse={value => value == "" ? "" : value}
                            />
                            <Field
                                sm={12} md={6}
                                component={InputField}
                                name="city"
                                fullWidth
                                label="City"
                                parse={value => value == "" ? "" : value}
                            />
                            <Field
                                sm={12} md={4}
                                name="state"
                                label="State"
                                component={SelectField}
                                options={STATES}
                                getOptionLabel={get("name")}
                                getOptionValue={get("value")}
                                fullWidth
                                allowNull
                            />
                            <Field
                                sm={12} md={4}
                                component={InputField}
                                name="zip_code"
                                fullWidth
                                label="Zip Code"
                                parse={value => value == "" ? "" : value}
                            />
                            <Field
                                sm={12} md={4}
                                component={InputField}
                                name="country"
                                fullWidth
                                label="Country"
                                parse={value => value == "" ? "" : value}
                            />
                        </GridGroup>
                    </Grid>
                    <Box marginTop={4}>
                        <If condition={!disableInnerSubmit}>
                            <Button variant="contained" color="primary" type="submit">
                                {labelSubmit}
                            </Button>
                        </If>
                    </Box>
                </Box>
            )}
        </Form>
    );
};

export default CustomerContactForm;